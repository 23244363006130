import API from "@/client/api";
import { loggerWithPrefix } from "@/lib/logger";
import { entityStores, entityStoresNano } from "@/stores/entityStore";
import { AdvancedSearchType, AssessmentContextResponse, AssessmentQAs, Question } from "@/types";
import { createId } from "@paralleldrive/cuid2";
import { atom, subscribeKeys } from "nanostores";
import { createContext, useContext } from "react";

const logger = loggerWithPrefix("[assessmentStore]");

export type AssessmentDescriptions = {
  description?: string;
  keywords?: string;
};

export type EntityAssessmentQAs = AssessmentQAs & {
  entityIsGenerating?: boolean;
};

export class AssessmentStore {
  contextId = atom<string | undefined>();
  contextVersion = atom<number | undefined>();
  questions = atom<Question[]>([]);

  qasByEntityId = atom<Record<string, EntityAssessmentQAs | undefined>>({});
  loadingByEntityId = atom<Record<string, boolean>>({});

  descriptions = atom<AssessmentDescriptions>({});

  init = async (assessmentContextId?: string) => {
    let context: Partial<AssessmentContextResponse> = {
      id: undefined,
      questions: [],
      version: undefined,
      descriptions: {},
    };

    if (assessmentContextId) {
      const contextRes = await API.assessmentContext.get(assessmentContextId);
      context = contextRes;
    }

    this.contextId.set(context.id);
    this.questions.set(context.questions ?? []);
    this.contextVersion.set(context.version);
    this.descriptions.set((context.descriptions as AssessmentDescriptions) ?? {});
    this.qasByEntityId.set({});
  };

  createAndInit = async (
    type: AdvancedSearchType,
    questions: Question[],
    descriptions: AssessmentDescriptions,
  ) => {
    const context = await API.assessmentContext.create({
      type,
      questions: questions,
      descriptions: descriptions,
    });
    await this.init(context.id);
  };

  updateDescriptions = async (newDescriptions: Partial<AssessmentDescriptions>) => {
    this.descriptions.set({
      ...this.descriptions.get(),
      ...newDescriptions,
    });
    return this.updateAssessmentContext();
  };

  addQuestion = (questionText: string) => {
    const question: Question = {
      id: createId(),
      question: questionText,
    };

    this.questions.set([...this.questions.get(), question]);
    void this.updateAssessmentContext();
  };

  removeQuestion = (id: string) => {
    const questions = this.questions.get();
    this.questions.set(questions.filter((q) => q.id !== id));
    void this.updateAssessmentContext();
  };

  private updateAssessmentContext = async () => {
    const contextId = this.contextId.get();
    if (!contextId) return;

    const context = await API.assessmentContext.update(contextId, {
      questions: this.questions.get(),
      descriptions: this.descriptions.get(),
    });
    this.contextVersion.set(context.version);
    return context;
  };

  getAssessmentQAs = async (entityId: string, searchFit?: number, regenerate = false) => {
    const contextId = this.contextId.get();
    if (!entityId || !contextId) return;

    let qas = this.qasByEntityId.get()[entityId];

    // Return existing QAs if they exist and we're not regenerating
    if (qas && !regenerate) return qas;

    if (regenerate) {
      logger.info("regenerating assessment QAs...");
      const currentQAs = this.qasByEntityId.get();
      const { [entityId]: _, ...newQAs } = currentQAs;
      this.qasByEntityId.set(newQAs);
    }

    // Set QAs to undefined to indicate that they are being loaded
    this.qasByEntityId.set({
      ...this.qasByEntityId.get(),
      [entityId]: undefined,
    });

    // Get the entity store for this entity ID
    const entityStore = entityStores[entityId];
    if (!entityStore) {
      subscribeKeys(entityStoresNano, [entityId], (value, changed) => {
        if (value[entityId]) {
          void this.getAssessmentQAs(entityId, searchFit, true);
        }
      });
      logger.warn("entity store not found for entityId", entityId);
      return;
    }

    // Set up a one-time subscription if the entity is not already generated
    if (!entityStore.isGenerated.get()) {
      const unsubscribe = entityStore.isGenerated.subscribe((isGenerated) => {
        if (isGenerated) {
          void this.getAssessmentQAs(entityId, searchFit, true);
          unsubscribe();
        }
      });

      // Set a special state to indicate entity generation in progress
      this.qasByEntityId.set({
        ...this.qasByEntityId.get(),
        [entityId]: {
          results: [],
          version: 0,
          entityIsGenerating: true,
        },
      });

      return this.qasByEntityId.get()[entityId];
    }

    this.loadingByEntityId.set({
      ...this.loadingByEntityId.get(),
      [entityId]: true,
    });

    try {
      qas = await API.answerAssessmentQuestions({
        entityId,
        assessmentContextId: contextId,
        regenerate,
        searchFit,
      });
    } catch (error) {
      logger.error("error getting assessment QAs", error);
      this.loadingByEntityId.set({
        ...this.loadingByEntityId.get(),
        [entityId]: false,
      });
      return;
    }

    this.qasByEntityId.set({
      ...this.qasByEntityId.get(),
      [entityId]: qas,
    });
    this.loadingByEntityId.set({
      ...this.loadingByEntityId.get(),
      [entityId]: false,
    });

    return qas;
  };
}

// Create a map of assessment stores by context ID
export const assessmentStores: Record<string, AssessmentStore> = {};

// Create a context for the current assessment store ID
export const AssessmentStoreContext = createContext<string | undefined>(undefined);

// Create a discover assessment store
assessmentStores["discover"] = new AssessmentStore();

// Hook to get the current assessment store
export const useAssessmentStore = () => {
  const contextId = useContext(AssessmentStoreContext);
  if (!contextId) {
    throw new Error(
      "useAssessmentStore called outside of assessment context. Please ensure you have AssessmentStoreContext.Provider in your component tree",
    );
  }
  if (!assessmentStores[contextId]) {
    assessmentStores[contextId] = new AssessmentStore();
  }
  return assessmentStores[contextId];
};

// For testing
export const getAssessmentStoreForTest = () => {
  if (!assessmentStores["test"]) {
    assessmentStores["test"] = new AssessmentStore();
  }
  return assessmentStores["test"];
};
