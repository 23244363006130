import { atom, computed, map } from "nanostores";

import API from "@/client/api";
import errorTracker from "@/lib/errorTracker";
import { loggerWithPrefix } from "@/lib/logger";
import { prettyError } from "@/lib/utils";
import { assessmentStores } from "@/stores/assessmentStore";
import { graphStore } from "@/stores/graphStore";
import listStore from "@/stores/listStore";
import { MessageStore, messageStore, MessageStoreType } from "@/stores/messageStore";
import { uiStore } from "@/stores/uiStore";
import {
  AdvancedSearchRequest,
  AdvancedSearchType,
  AssessmentContextResponse,
  BuiltinStartingSet,
  ChatRole,
  CompanyQueryField,
  Criteria,
  FieldCriteria,
  FilterQuery,
  InvestorQueryField,
  isCompoundCriteria,
  isCriteria,
  ListEntrySpec,
  ListOverview,
  OpenAIChatMessage,
  QueryField,
  SearchEntityType,
  SearchQueryAPIResult,
  SearchQueryStats,
  SearchRanking,
  SearchRankingSortDefault,
  SmartSearchResponse,
} from "@/types";
import { createId } from "@paralleldrive/cuid2";
import { SavedSearch } from "@prisma/client";
import { deepEqual } from "fast-equals";
import { toast } from "react-toastify";

const logger = loggerWithPrefix("[discoverStore]");

export enum QueryEditMode {
  Filter,
  Ranking,
  Question,
}

class DiscoverStore {
  searchId = atom<string | undefined>();
  searchName = atom<string>("Smart Search");

  // Add a new atom to track the active query execution
  activeQueryId = atom<string | undefined>();

  searchHistory = atom<SavedSearch[]>([]);
  searchHistoryLoading = atom<boolean>(true);

  lists = atom<ListOverview[]>([]);

  startingSet = atom<BuiltinStartingSet>(BuiltinStartingSet.Linkedin);

  plainTextRequest = atom("");

  queryError = atom<string | undefined>();

  queryType = atom<AdvancedSearchType>(AdvancedSearchType.Other);
  entityType = atom<SearchEntityType>("people");
  itemType = computed([this.queryType, this.entityType], (queryType, entityType) => {
    if (queryType === AdvancedSearchType.Other) {
      return entityType;
    }
    return queryType;
  });

  query = atom<FilterQuery>([]);

  recentlyRunQuery = atom<FilterQuery | undefined>();

  submittingQueryState = atom<string | undefined>();

  // all results is the sorted list of search results across all pages
  allResults = atom<SearchQueryAPIResult[] | undefined>();

  // these were selected by search but the re-ranker moved them off the page
  rejectedResults = atom<SearchQueryAPIResult[]>([]);

  // relevanceScores is a map of result id to reranker relevance score
  relevanceScores = map<Record<string, number>>({});

  // the current page that the user is on
  resultsPage = atom<number>(0);

  // the number of results per page
  resultsPerPage = atom<number>(30);

  // the total number of results from elastic
  resultStats = atom<{ value: number; relation: string; globalTotal: number } | undefined>();

  totalResultCount = computed(
    [this.resultStats, this.allResults],
    (rs, all) => rs?.value || all?.length || 0,
  );

  selectedResultEntityId = atom<string | undefined>();
  debugMode = atom<boolean>(false);
  skipRelevance = atom<boolean>(false);

  // the current page of results to show
  currentResults = computed(
    [this.allResults, this.resultsPage, this.resultsPerPage],
    (all, page, perPage) => {
      return all?.slice(page * perPage, (page + 1) * perPage);
    },
  );

  currentMaxPages = computed([this.allResults, this.resultsPerPage], (all, perPage) => {
    return Math.ceil((all?.length || 0) / perPage) + 1;
  });

  // If editMode is undefined - no editing is happening.
  editMode = atom<QueryEditMode | undefined>();
  fieldBeingEdited = atom<QueryField | undefined>();

  ranking = atom<SearchRanking>({});

  sidePanelHasContent = computed([this.editMode, this.selectedResultEntityId], (em, sre) => {
    return em !== undefined || !!sre;
  });

  isAgentSearch = atom<boolean>(false);
  hasAutoOpened = atom<boolean>(false);

  // Selection related atoms
  selectedIds = atom<Set<string>>(new Set());
  selectedAll = atom<boolean>(false);

  // --- actions

  init = (lists: ListOverview[]) => {
    this.lists.set(lists);
  };

  loadSearch = async ({
    search,
    searchResults,
    searchResultStats,
    assessmentContext,
  }: {
    search: SavedSearch;
    searchResults: SearchQueryAPIResult[] | undefined;
    searchResultStats: SearchQueryStats | undefined;
    assessmentContext?: AssessmentContextResponse;
  }) => {
    logger.info("loading search", search, searchResults);
    this.searchId.set(search.id);
    this.searchName.set(search.name);
    this.query.set(search.query as FilterQuery);
    this.queryType.set(search.type as AdvancedSearchType);
    this.entityType.set(search.entityType as "people" | "companies");
    this.ranking.set((search.ranking || {}) as SearchRanking);
    this.isAgentSearch.set(location.pathname.includes("/agent/"));

    if (searchResults) {
      this.allResults.set(searchResults);
      this.resultStats.set(searchResultStats);
      const relevanceMap = Object.fromEntries(
        searchResults
          .map((r) => [r.id, r.relevanceScore])
          .filter(([_, score]) => score !== undefined),
      ) as Record<string, number>;
      this.relevanceScores.set(relevanceMap);

      const selectedId = this.selectedResultEntityId.get();
      const perPage = this.resultsPerPage.get();
      const entityIndex = searchResults.findIndex((result) => result.id === selectedId);
      if (entityIndex !== -1) {
        const page = Math.floor(entityIndex / perPage);
        this.resultsPage.set(page);
      } else {
        this.resultsPage.set(0);
      }
    } else {
      void this.submitQuery();
    }

    const assessmentStore = assessmentStores["discover"];

    // Now we can safely initialize
    assessmentStore.contextId.set(assessmentContext?.id);
    await assessmentStore.init(assessmentContext?.id);
    assessmentStore.questions.set(assessmentContext?.questions ?? []);
  };

  initSelectedEntity = (entityId: string) => {
    this.selectedResultEntityId.set(entityId);
  };

  initializeSearchType = (queryType: AdvancedSearchType, entityType: SearchEntityType) => {
    this.queryType.set(queryType);
    this.entityType.set(entityType);
  };

  smartInitialize = async () => {
    const payload = {
      queryType: this.queryType.get(),
      entityType: this.entityType.get(),
    };

    const response = await API.smartSearchInitialize(payload);
    logger.info("initializeSearch", payload, response);

    this.query.set([...this.query.get(), ...response.query]);
    this.ranking.set({
      ...this.ranking.get(),
      ...response.ranking,
    });
  };

  getSearchNoun = () => {
    const queryType = this.queryType.get();
    const entityType = this.entityType.get();
    return queryType === AdvancedSearchType.Other ? entityType : queryType;
  };

  clear = () => {
    this.searchId.set(undefined);
    this.query.set([]);
    this.allResults.set(undefined);
    this.resultStats.set(undefined);

    const assessmentStore = assessmentStores["discover"];
    if (assessmentStore) void assessmentStore.init();
  };

  startOver = () => {
    this.init(this.lists.get());
  };

  resetEditMode = () => {
    this.editMode.set(undefined);
    this.fieldBeingEdited.set(undefined);
  };

  beginEditCriterion = (field: QueryField | undefined) => {
    this.resetEditMode();
    this.editMode.set(QueryEditMode.Filter);
    this.fieldBeingEdited.set(field);
  };

  beginAddCriterion = () => {
    this.resetEditMode();
    this.editMode.set(QueryEditMode.Filter);
  };

  beginAddRankingCriterion = () => {
    this.resetEditMode();
    this.editMode.set(QueryEditMode.Ranking);
  };

  beginAddQuestion = () => {
    this.resetEditMode();
    this.editMode.set(QueryEditMode.Question);
  };

  updateRanking = (update: Partial<SearchRanking>, requery?: boolean) => {
    const ranking = this.ranking.get();
    this.ranking.set({ ...ranking, ...update });
    if (requery) {
      void this.submitQuery({ interruptExisting: true });
    }
  };

  generateSearchPlan = async (prompt: string): Promise<SmartSearchResponse> => {
    logger.info("submitting request", this.startingSet.get(), "-", prompt);
    if (!this.plainTextRequest.get()) {
      this.plainTextRequest.set(prompt);
    }

    try {
      const response = await API.smartSearchGeneratePlan({
        prompt,
        request: {
          searchId: this.searchId.get() || "new search",
          query: this.query.get(),
          ranking: this.ranking.get(),
          queryType: this.queryType.get(),
          entityType: this.entityType.get(),
          startingSet: this.startingSet.get(),
        },
      });

      if (response.query) {
        this.query.set(response.query);
      }
      if (response.ranking) {
        this.updateRanking(response.ranking);
      }

      return response;
    } catch (e) {
      errorTracker.sendError(e);
      toast.error(prettyError(e));
      return { message: "Error generating search plan" };
    }
  };

  async newQuery(query: FilterQuery) {
    this.query.set(query);

    // Create a new assessment context
    const assessmentStore = assessmentStores["discover"];
    try {
      await assessmentStore.createAndInit(
        this.queryType.get(),
        assessmentStore.questions.get(),
        this.ranking.get(),
      );
    } catch (e) {
      errorTracker.sendError(e, { query: JSON.stringify(query) });
    }

    const prepared = this.prepareSearchQuery(false);
    const response = await API.smartSearchCreate({
      ...prepared,
      name: this.searchName.get(),
      assessmentContextId: assessmentStore.contextId.get(),
    });

    if (response.searchId) {
      logger.info("routing to search", response.searchId);
      uiStore.routeTo("/search/advanced/" + response.searchId);
    }

    return response.searchId;
  }

  updateQuery(query: FilterQuery) {
    this.query.set(query);
    this.runQueryIfChanged(query);
  }

  switchEntityType(mode: "people" | "companies") {
    this.entityType.set(mode);
    // Reset the active query ID to ensure any old queries are abandoned
    this.activeQueryId.set(undefined);
    void this.submitQuery();
  }

  runQueryIfChanged(query: FilterQuery) {
    const prevQuery = this.recentlyRunQuery.get();
    const withoutEmpty = query.filter((f) => !isCriteria(f) || f.field);
    if (!deepEqual(prevQuery, withoutEmpty)) {
      // Reset the active query ID to ensure any old queries are abandoned
      this.activeQueryId.set(undefined);
      void this.submitQuery();
    }
  }

  // ---- START search-related stuff

  /**
   * there is a 3 part process here:
   *
   * we do the Elastic search, getting 40 entities ranked by Elastic
   * we send them to the LLM re-ranker, getting relevancy scores for all 40
   * we then slice the top 30 to treat as actual results
   * the rejected 10 get added the rejected list
   *
   * When the user goes to the next page:
   * we get a new 40 entities (excluding both the displayed + rejected ones)
   * we re-rank those new 40
   * we blend our previously rejected entities, we now have 50, and take the top 30 again
   * we have our next page of results. we now also have 20 rejects
   */

  async submitQuery({
    // if false, we're starting a new query
    loadNextPage = false,
    // if true, perform query even if there is an existing one
    interruptExisting = false,
    // if true, skip re-ranking
    skipReranking = false,
    // if true, skip saving the results to the saved search
    skipSave = false,
  }: {
    loadNextPage?: boolean;
    interruptExisting?: boolean;
    skipReranking?: boolean;
    skipSave?: boolean;
  } = {}) {
    if (this.activeQueryId.get() && !interruptExisting) {
      logger.info("already submitting query, skipping");
      return;
    }
    this.submittingQueryState.set("Preparing to search");

    // Generate a unique ID for this query execution
    const queryExecutionId = createId();
    this.activeQueryId.set(queryExecutionId);

    try {
      if (!loadNextPage) {
        this.allResults.set(undefined);
        this.rejectedResults.set([]);
        this.resultsPage.set(0);
        this.relevanceScores.set({});
      }

      const request = this.prepareSearchQuery(loadNextPage);
      if (!request.query.length) {
        toast.error("No query provided");
        return;
      }

      const { newResults, stats, perPage } = await this.executeSearchQuery(
        request,
        loadNextPage,
        queryExecutionId,
      );

      // Check if this query is still the active one
      if (this.activeQueryId.get() !== queryExecutionId) {
        logger.info("Query execution aborted, a newer query has taken precedence");
        return;
      }

      // Execute reranking
      this.submittingQueryState.set("Assessing fit");
      const rankedResults = await this.calculateRelevance(
        newResults,
        request,
        queryExecutionId,
        skipReranking,
      );
      this.processSearchResults(request.searchId, rankedResults, stats, perPage, loadNextPage);

      if (!skipSave) {
        void API.smartSearchSave({
          searchId: request.searchId,
          results: this.allResults.get() || [],
        });
      }

      return rankedResults;
    } catch (e) {
      this.handleSearchError(e);
    } finally {
      // Only clear the submitting state if this is still the active query
      if (this.activeQueryId.get() === queryExecutionId) {
        this.submittingQueryState.set(undefined);
        this.activeQueryId.set(undefined);
      }
    }
  }

  private prepareSearchQuery(loadNextPage: boolean): AdvancedSearchRequest {
    // Initialize and set up search parameters
    let searchId = this.searchId.get();
    if (!searchId) {
      searchId = createId();
      this.searchId.set(searchId);
    }

    // Prepare query and ranking
    const query = this.query.get();
    const withoutEmpty = query.filter((f) => !isCriteria(f) || f.field);

    // Process ranking information
    const ranking = this.ranking.get();
    const investmentRoundFilter = query.find(
      (q) => isCriteria(q) && q.field === InvestorQueryField.investmentRounds,
    );

    if (investmentRoundFilter) {
      const investmentRounds =
        isCompoundCriteria(investmentRoundFilter) ?
          []
        : investmentRoundFilter.include?.map((i: string) => i.trim()) || [];
      ranking.investmentRounds = investmentRounds;
    }

    // Store prepared data
    this.recentlyRunQuery.set(withoutEmpty);
    this.queryError.set(undefined);
    const queryType = this.queryType.get();
    const entityType = this.entityType.get();

    if (!loadNextPage) {
      this.selectedAll.set(false);
      this.selectedIds.set(new Set());
    }

    const request: AdvancedSearchRequest = {
      searchId,
      query: withoutEmpty,
      ranking,
      entityType,
      queryType,
      startingSet: this.startingSet.get(),
    };

    logger.info("prepared query", request);
    return request;
  }

  shouldRerank = (ranking: SearchRanking) => {
    return ranking.description || ranking.keywords || ranking.targetAudience;
  };

  private async executeSearchQuery(
    request: AdvancedSearchRequest,
    loadNextPage: boolean,
    queryExecutionId: string,
  ): Promise<{
    newResults: SearchQueryAPIResult[];
    stats?: SearchQueryStats;
    perPage: number;
  }> {
    this.submittingQueryState.set("Collecting results");

    if (!loadNextPage) {
      this.allResults.set(undefined);
    }

    const currentResults = this.allResults.get() || [];
    const skipIds =
      loadNextPage ?
        [...currentResults.map((r) => r.id), ...this.rejectedResults.get().map((r) => r.id)]
      : undefined;
    const assessmentStore = assessmentStores["discover"];
    const assessmentContextId = assessmentStore.contextId.get();
    const perPage = this.resultsPerPage.get();

    // Execute initial query
    const loadExtra = this.shouldRerank(request.ranking) ? 15 : 0; // extra results to load to account for low-fit rejections
    const searchResponse = await API.smartSearchQuery({
      ...request,
      name: this.searchName.get(),
      skipIds,
      assessmentContextId: assessmentContextId,
      count: perPage + loadExtra,
    });

    // Check if this query is still the active one after getting initial results
    if (this.activeQueryId.get() !== queryExecutionId) {
      logger.info(
        "Query execution aborted after initial results, a newer query has taken precedence",
      );
      throw new Error("Query aborted");
    }

    logger.info("got query results", searchResponse, loadNextPage);

    if (request.queryType === AdvancedSearchType.Investors) {
      // add note based on investing activity
      const investmentRounds = (this.ranking.get().investmentRounds || []).filter(Boolean);
      searchResponse.results.forEach((r) => {
        const investmentsByRound = r.investmentsByRound || {};
        const totalRelevantInvestments = investmentRounds.reduce(
          (acc, key) => acc + (investmentsByRound[key] || 0),
          0,
        );
        r.scoreText = `${totalRelevantInvestments} recent ${investmentRounds.join(" / ")} investments`;
      });
    }

    // Check if this query is still the active one before reranking
    if (this.activeQueryId.get() !== queryExecutionId) {
      logger.info("Query execution aborted before reranking, a newer query has taken precedence");
      throw new Error("Query aborted");
    }

    return {
      newResults: searchResponse.results,
      stats: searchResponse.total,
      perPage,
    };
  }

  private async calculateRelevance(
    initialResults: SearchQueryAPIResult[],
    request: AdvancedSearchRequest,
    queryExecutionId: string,
    skipReranking = false,
  ): Promise<SearchQueryAPIResult[]> {
    const currentRelevanceMap = this.relevanceScores.get();
    if (!this.shouldRerank(request.ranking) || skipReranking) {
      logger.info("no ranking text provided, skipping reranking");
      this.skipRelevance.set(true);
      return initialResults;
    }
    this.skipRelevance.set(false);

    try {
      const needsRanking = initialResults.filter((r) => !currentRelevanceMap[r.id]);
      const rerankedResponse = await API.smartSearchRerank({
        ...request,
        results: needsRanking,
      });

      // Check again after the API call
      if (this.activeQueryId.get() !== queryExecutionId) {
        logger.info("Reranking results discarded, a newer query has taken precedence");
        throw new Error("Query aborted");
      }

      logger.info("re-ranked results", rerankedResponse);
      if (!rerankedResponse.results.length) {
        logger.info("uh oh, no re-ranked results");
        return initialResults;
      }

      // Process reranking results
      const newRelevanceScores = Object.fromEntries(
        rerankedResponse.results.map((r) => [r.id, r.score]),
      );
      const newMap = {
        ...currentRelevanceMap,
        ...newRelevanceScores,
      };
      this.relevanceScores.set(newMap);

      const sortByRelevance = (request.ranking.sort || SearchRankingSortDefault) == "relevance";
      const newRelevanceMap = Object.fromEntries(rerankedResponse.results.map((r) => [r.id, r]));

      const maxScore = Math.max(...initialResults.map((r) => r.score || 0));
      const combinedResults: SearchQueryAPIResult[] =
        initialResults.map((r) => {
          const normalizedScore = ((r.score || 0) * 5) / maxScore; // normalize score to 5
          const relevanceScore = Math.min(newRelevanceScores[r.id], 4); // cap at 4
          return {
            ...r,
            score: normalizedScore,
            reasons: newRelevanceMap[r.id].matches,
            relevanceScore,
            // if we're sorting by relevance, use the reranker score
            // otherwise, treat fit as a binary yes/no
            combinedScore:
              sortByRelevance ?
                relevanceScore * 100 + normalizedScore
              : (relevanceScore > 2 ? 100 : 0) + normalizedScore,
          };
        }) || [];

      combinedResults.sort((a, b) => (b.combinedScore || 0) - (a.combinedScore || 0));

      return combinedResults;
    } catch (e) {
      logger.error("error reranking", e);
      return initialResults;
    }
  }

  // eslint-disable-next-line @typescript-eslint/max-params
  private processSearchResults(
    searchId: string,
    newResults: SearchQueryAPIResult[],
    stats?: SearchQueryStats,
    perPage?: number,
    loadNextPage = false,
  ): void {
    const currentResults = this.allResults.get() || [];
    const rejectedResults = this.rejectedResults.get() || [];

    // merge rejected and new results to decide what to show
    const mergedResults = [...rejectedResults, ...newResults];
    mergedResults.sort((a, b) => (b.combinedScore || 0) - (a.combinedScore || 0));

    const resultsToShow = mergedResults.slice(0, perPage);
    const newRejectedResults = mergedResults.slice(perPage);

    const results = loadNextPage ? [...currentResults, ...resultsToShow] : resultsToShow;
    this.allResults.set(results);
    this.rejectedResults.set(newRejectedResults);

    if (perPage) {
      this.resultsPage.set(Math.max(0, Math.floor(results.length / perPage) - 1));
    }

    if (!loadNextPage) {
      if (stats) this.resultStats.set({ ...this.resultStats.get(), ...stats });
      if (perPage) this.resultsPerPage.set(perPage);
    }

    // Extract entity IDs and calculate connection scores
    const entityIds = results.map((result) => result.id);
    void graphStore.preloadGraphScores(entityIds);
  }

  rerunSearch = () => {
    // Reset the active query ID to ensure any old queries are abandoned
    this.activeQueryId.set(undefined);
    this.allResults.set(undefined);
    this.relevanceScores.set({});
    void this.submitQuery();
  };

  private handleSearchError(e: unknown) {
    if (String(e) === "Query aborted") {
      return;
    }
    this.queryError.set(prettyError(e));
  }

  // ---- END search-related stuff

  jumpToPage(page: number) {
    this.resultsPage.set(page);
  }

  loadNextPage() {
    // Reset the active query ID to ensure any old queries are abandoned
    this.activeQueryId.set(undefined);
    void this.submitQuery({ loadNextPage: true });
  }

  async loadSearchHistory() {
    this.searchHistoryLoading.set(true);
    const response = await API.savedSearch.list();
    this.searchHistory.set(response);
    this.searchHistoryLoading.set(false);
  }

  deleteSearch = async (searchId: string) => {
    try {
      await API.savedSearch.delete(searchId);
      const currentHistory = this.searchHistory.get();
      this.searchHistory.set(currentHistory.filter((search) => search.id !== searchId));
    } catch (e) {
      toast.error("Failed to delete search");
    }
  };

  openEntity = (entityId: string | undefined) => {
    this.selectedResultEntityId.set(entityId);
    const path = location.pathname.split("/selectedEntity")[0];
    uiStore.routeTo(entityId ? `${path}/selectedEntity/${entityId}` : path);
    this.resetEditMode();
  };

  // Selection related methods
  toggleSelected = (id: string) => {
    const selected = this.selectedIds.get();
    const newSelected = new Set(selected);

    if (newSelected.has(id)) {
      newSelected.delete(id);
      this.selectedAll.set(false);
    } else {
      newSelected.add(id);
    }

    this.selectedIds.set(newSelected);
  };

  selectAllOnPage = () => {
    const currentResults = this.currentResults.get();
    if (!currentResults) return;

    const selected = this.selectedIds.get();
    const newSelected = new Set(selected);
    currentResults.forEach((r) => newSelected.add(r.id));
    this.selectedIds.set(newSelected);
  };

  selectAll = () => {
    const allResults = this.allResults.get();
    if (!allResults) return;

    const newSelected = new Set(allResults.map((r) => r.id));
    this.selectedIds.set(newSelected);
    this.selectedAll.set(true);
  };

  clearSelection = () => {
    this.selectedIds.set(new Set());
    this.selectedAll.set(false);
  };

  bulkAddToList = async (listId: string) => {
    const selectedIds = this.selectedIds.get();
    const selectedAll = this.selectedAll.get();

    if (selectedAll) {
      // If selecting all, make a direct API call to save all results
      const request = this.prepareSearchQuery(false);
      const count = listStore.maxSize.get();
      await toast.promise(
        API.smartSearchSaveToList({
          ...request,
          count,
          saveToListId: listId,
        }),
        {
          pending: "Adding all results (this might take a while)...",
          error: "Failed to add all results",
        },
      );
    } else {
      // If only selecting specific items, use the existing list add method
      const allResults = this.allResults.get() || [];
      const selectedResults = allResults.filter((r) => selectedIds.has(r.id));
      await this.bulkAddSelectedToList(listId, selectedResults);
    }

    toast.success("Done! Click to open list in new tab", {
      onClick: () => {
        window.open(`${window.location.origin}/lists/${listId}`, "_blank");
      },
    });
  };

  bulkAddSelectedToList = async (
    listId: string,
    items: SearchQueryAPIResult[],
    customMessage?: string,
  ) => {
    if (!items.length) {
      return;
    }

    const entrySpecs = items.map(
      (item) =>
        ({
          type: "entity",
          entityId: item.id,
          data: {
            name: item.name,
          },
        }) as ListEntrySpec,
    );
    await toast.promise(
      API.listEntries.create(listId, {
        entrySpecs,
      }),
      {
        pending: customMessage || "Adding to list...",
        error: "Failed to add to list",
      },
    );
  };
}

export const discoverStore = new DiscoverStore();
